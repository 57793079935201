<template>
  <div class="box has-background-light">
    <div class="columns">
      <div class="column" v-if="isCompanionUser">
        <SummaryBox label="Companion" :value="usage.companion" :image="true" />
      </div>
      <div class="column" v-if="hasAutoCheck">
        <SummaryBox
          label="AutoChecks"
          :value="usage.autocheck"
          icon-color="has-text-light"
          icon-class="fas fa-check"
        />
      </div>
      <div class="column">
        <SummaryBox
          label="Vehicle Profile"
          :value="usage.vpp"
          icon-color="has-text-light"
          icon-class="fas fa-search"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountHistorySummaryBoxes',
  components: {
    SummaryBox: () => import('core/components/SummaryBox')
  },
  props: {
    usage: {
      type: Object,
      required: true
    },
    isCompanionUser: {
      type: Number,
      required: true
    },
    hasAutoCheck: {
      type: Number,
      required: true
    }
  }
}
</script>
